








































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import UtilMixin from "@/mixins/utilMixin";
import * as appDate from "#/utility/appDate";
import { CalendarDispEvent, Layer } from "#/components/calendar/common";
import NowButton from "#/components/calendar/NowButton.vue";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import MyCalendarFilterButton from "@/components/calendar/myCalendar/MyCalendarFilterButton.vue";
import MypageCalendar from "@/components/calendar/myCalendar/MypageCalendar.vue";
import {
  Document,
  DefaultDocument,
  SetColor,
  DefaultDocumentSetColor
} from "@/components/calendar/types";
import { LAYER_ID } from "#/model/schedule/calendarLayer";

@Component({
  components: {
    NowButton,
    CalendarTitleBeforeAfter,
    MyCalendarFilterButton,
    MypageCalendar
  }
})
export default class extends Mixins(AxiosMixin, FireStoreMixin, UtilMixin) {
  /** カレンダーrefオブジェクト */
  @Ref("calendar") private readonly calendar!: MypageCalendar;

  /** カレンダー表示形式変更検知（日・週・月） */
  @Watch("document.type") private changeDocumentType() {
    if (this.documentId !== "") {
      this.documentSave(this.document);
    }
  }

  /** カレンダーレイヤーの変更検知 */
  @Watch("document.selectLayers", { deep: true }) private changeLayer() {
    if (this.documentId !== "") {
      this.documentSave(this.document);
    }
  }

  /** カレンダー選択中の日付 */
  private focus = appDate.dateToStr(new Date(), "yyyy-MM-dd");

  /** マイページイベント */
  private mypage_events: CalendarDispEvent[] = [];
  /** 職員イベント */
  private staff_events: CalendarDispEvent[] = [];
  /** 祝日イベント */
  private holiday_events: CalendarDispEvent[] = [];
  /** シフトイベント */
  private shift_events: CalendarDispEvent[] = [];
  /** 訪問予定イベント */
  private visit_plan_events: CalendarDispEvent[] = [];

  /** マイページレイヤー */
  private mypage_layers: Layer[] = [];
  /** 職員レイヤー */
  private staff_layers: Layer[] = [];
  /** その他レイヤー */
  private other_layers: Layer[] = [];

  /** 選択状態 （fire store 保持） */
  private document = DefaultDocument();

  /** 職員カレンダーの情報 （fire store 保持） */
  private docStaffCalendar = DefaultDocumentSetColor();

  /** 全てのレイヤー */
  private layers: Layer[] = [];

  /** カレンダー選択中の日付の年月形式 */
  private get FormatDateYM(): string {
    return appDate.dateToStr(appDate.strToDate(this.focus), "yyyy-MM");
  }

  /** カレンダーレイヤーが存在しているかどうか */
  private get ExistLayers(): boolean {
    return this.mypage_layers && this.mypage_layers.length > 0;
  }

  /** フィルターされたカレンダーイベント */
  private get FilteredEvent() {
    let events: CalendarDispEvent[] = [];

    const selectLayerIds = this.document.selectLayers
      .filter(layer => layer.checked)
      .map(layer => layer.layerId);

    if (selectLayerIds) {
      for (const layerId of selectLayerIds) {
        // マイカレンダー
        events = events.concat(
          this.filterEventByLayer(
            layerId,
            this.mypage_layers,
            this.mypage_events
          )
        );

        // 職員
        events = events.concat(
          this.filterEventByLayer(layerId, this.staff_layers, this.staff_events)
        );

        // 訪問予定の場合
        if (layerId == LAYER_ID.VISIT) {
          events = events.concat(this.visit_plan_events);
        }

        // シフトの場合
        if (layerId == LAYER_ID.SHIFT) {
          events = events.concat(this.shift_events);
        }

        // 祝日の場合
        if (layerId == LAYER_ID.HOLIDAY) {
          events = events.concat(this.holiday_events);
        }
      }
    }

    return events;
  }

  async created(): Promise<void> {
    // 先に職員カレンダーのdocument取得
    await this.getStaffDocument();

    await this.getMyCalendarDocument();

    this.fetch();
  }

  /** APIからフェッチ */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/layer/get",
      { target_ym: this.FormatDateYM },
      res => {
        if (res.data) {
          this.mypage_events = res.data.mypage_events;
          this.staff_events = res.data.staff_events;
          this.holiday_events = res.data.holiday_events;
          this.shift_events = res.data.shift_events;
          this.visit_plan_events = res.data.visit_plan_events;
          this.mypage_layers = res.data.mypage_layers;
          this.staff_layers = res.data.staff_layers;
          this.other_layers = res.data.other_layers;
          this.setLayerColor();
          this.setLayers();
        }
      }
    );
  }

  // 職員レイヤーカラー設定
  private setLayerColor() {
    this.staff_layers.forEach(layer => {
      // 職員が色設定済みならindex取得
      if (!this.docStaffCalendar.setting) {
        return;
      }
      const staffColorDoc = this.docStaffCalendar.setting.find(item => {
        return item.id === layer.staff_id;
      });
      if (staffColorDoc && staffColorDoc.color) {
        layer.color = staffColorDoc.color;
      }
      //シフト
      this.setEventColor(layer, this.shift_events, layer.color);
      //訪問予定
      this.setEventColor(layer, this.visit_plan_events, layer.color);
      //職員予定
      this.setEventColor(layer, this.staff_events, layer.color);
    });
  }

  /** レイヤーを設定する */
  private setLayers() {
    const layers = this.mypage_layers
      .concat(this.staff_layers)
      .concat(this.other_layers);

    this.layers = layers.sort((x, y) => {
      const xIndex = this.getSortIndex(x.id);
      const yIndex = this.getSortIndex(y.id);
      return xIndex - yIndex;
    });
  }

  /** カレンダーをクリックしたときに、イベント編集ダイアログを開く（新規作成） */
  private clickCalendar(event: CalendarEvent) {
    this.calendar.editEventDialog.open(0, event.date, event.hour, event.minute);
  }

  /** 予定を作成するボタンをクリックしたときに、イベント編集ダイアログを開く */
  private async clickAddEvent() {
    if (!this.ExistLayers) {
      await this.$openAlert("カレンダーが登録されていません");
      return;
    }
    const d = appDate.strToDateObj();
    const date = appDate.dateToStr(d, "yyyy-MM-dd");
    this.calendar.editEventDialog.open(0, date, d.getHours(), d.getMinutes());
  }

  /** イベントに色を設定する */
  private setEventColor(
    layer: Layer,
    events: CalendarDispEvent[],
    color: string
  ) {
    for (const event of events) {
      if (event.layer_id == layer.id || event.staff_id == layer.staff_id) {
        event.color = color;
        const styleSplit = event.style.split(";");
        event.style = styleSplit[0] + ";" + "border-color: " + color;
      }
    }
  }

  /** イベントをフィルターで絞り込む */
  private filterEventByLayer(
    layerId: number,
    layers: Layer[],
    layerEvents: CalendarDispEvent[]
  ) {
    const events: CalendarDispEvent[] = [];
    const targetLayer = layers.find(v => v.id == layerId);
    if (targetLayer) {
      for (const event of layerEvents) {
        if (event.layer_id == targetLayer.id) {
          events.push(event);
        }
      }
    }
    return events;
  }

  /** ソート用インデックスを取得する */
  private getSortIndex(id: number): number {
    let index = this.document.selectLayers.findIndex(z => z.layerId == id);
    if (index == -1) {
      index = 9999;
    }
    return index;
  }

  /** 職員カレンダードキュメントを取得する */
  private async getStaffDocument() {
    // 先に職員カレンダーのdocument取得
    this.collection = "staff-calendars-set-colors";
    if (this.loginUser && this.loginUser.system_id) {
      this.documentId = String(this.loginUser.system_id);
      const dataStaffCalendar = await this.documentGet();

      if (dataStaffCalendar) {
        this.docStaffCalendar = dataStaffCalendar as SetColor;
      } else {
        this.docStaffCalendar = DefaultDocumentSetColor();
      }
    }
  }

  /** マイページカレンダードキュメントを取得する */
  private async getMyCalendarDocument() {
    this.collection = "mycalendars";
    if (this.loginUser) {
      this.documentId = String(this.loginUser.id);
    }
    const data = (await this.documentGet()) as Document;
    if (data) {
      this.document = data;
    } else {
      this.document = DefaultDocument();
    }
  }
}
